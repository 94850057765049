@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');

$spacer: 20px;
$drawerWidth: 240px;

$primary: #1d2877;
$secundary: #464c8e;
$third: #edf0f7;
$green: #3dcd58;
$color-primary: #4f4e50;
$co2Primary: #1d2877;
$co2Secundary: #7acdf1;

$green-gradient: linear-gradient(180deg, rgba(92, 229, 118, 1) 0%, rgba(42, 78, 48, 1) 100%);

//*********************margin-padding*******************/

.d-flex {
  display: flex;
}

.pt-2 {
  padding-top: $spacer * 0.5;
}

.pl-2 {
  padding-left: $spacer * 0.5;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-2 {
  padding-bottom: $spacer * 0.5;
}

.pr-2 {
  padding-right: $spacer * 0.5;
}

.pr-3 {
  padding-right: $spacer * 0.7 !important;
}

.pr-0 {
  padding-right: 0px;
}

.pl-3 {
  padding-left: $spacer * 0.7 !important;
}

.pr-6 {
  padding-right: $spacer * 2.7 !important;
}

.pl-6 {
  padding-left: $spacer * 2.7 !important;
}

.pr-5 {
  padding-right: $spacer !important;
}

.pl-5 {
  padding-left: $spacer !important;
}

.mr-3 {
  margin-right: $spacer * 0.7;
}

.mr-2 {
  margin-right: $spacer * 0.5 !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.pa-3 {
  padding: $spacer;
}

.pa-0 {
  padding: 0px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-5px {
  margin: 5px !important;
}

.ma-10px {
  margin: 10px !important;
}

.padding-none {
  padding: 0px !important;
}

.mt-1000px {
  margin-top: 1000px;
}

.mt-8px {
  margin-top: 8px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.mt-14px {
  margin-top: 14px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-20px {
  margin: 20px !important;
}
.mt-30px {
  margin: 30px !important;
}

.margin-lef {
  margin-left: 80px !important;
}
//****************** LAYOUT PRINCIPAL ****************//@at-root

.app-content {
  @extend .w-100;
}

@media only screen and(min-height: 230px) {
  .app-bar {
    height: 8vh;
  }

  .app-toolbar {
    height: 8vh;
    padding-right: 100px;
  }

  .app-content {
    height: 100vh;
    padding-top: 8vh;
    flex-grow: 1;
    overflow: auto;
  }
  .app-content-no-logged {
    height: 100%;
    width: 100%;
  }
}

@media only screen and(min-height: 1280px) {
  .app-bar {
    height: 6vh;
  }

  .app-toolbar {
    height: 6vh;
    padding-right: 100px;
  }

  .app-content {
    height: 100vh;
    padding-top: 6vh;
    flex-grow: 1;
    overflow: auto;
  }
}

.root {
  display: flex;
  height: 100vh;
}

/******BAR***/

.item-navbar-text .MuiTypography-root {
  text-decoration: none;
  color: black;
  @extend .fw-bold;
}

.item-menu-button-text .MuiTypography-root {
  text-decoration: none;
  color: $green;
  @extend .fw-bold;
}

.item-navbar {
  text-decoration: none;
}

/*************** TEXT ******/
@at-root .font-bold {
  font-weight: bold;
}
.center-midd {
  padding: 70px 0;
  border: 3px;
  text-align: center;
}

.td-solid {
  text-decoration: solid !important;
}

.td-underline {
  text-decoration: underline !important;
}

.fs-xxlarge {
  font-size: xx-large;
}

.fs-info {
  font-size: 2.5rem !important;
}
.fs-t-cell {
  font-size: 1.8rem !important;
}
.fs-t-text {
  font-size: 1.2rem !important;
}
.fs-title-graphic {
  font-size: 1rem;
}
.fs-t-title-co2 {
  font-size: 3.5rem !important;
}

.f-color-principal {
  color: $color-primary;
}

.t-center-important {
  text-align: center !important;
}

.t-center {
  text-align: center !important;
}
.t-justify {
  text-align: justify !important;
}
.ws-pre-wrap {
  white-space: pre-wrap;
}

.fs-Large {
  font-size: large !important;
}

.fc-white {
  color: white !important;
}

.fc-primary {
  color: $primary !important;
}

.fc-third {
  color: $third !important;
}
.fc-text-co2-secundary {
  color: $co2Secundary !important;
}
.fc-text-co2-primary {
  color: $co2Primary !important;
}

.fc-secundary {
  color: #7acdf1 !important;
}

.fc-green {
  color: $green !important;
}

.fw-bold {
  font-weight: bold !important;
}

.w-width-300px {
  width: 300px;
}
.w-width-260px {
  width: 260px !important;
}

.w-width-98 {
  width: 98%;
}

.fs-t-title-co2 {
  font-size: 3.5rem !important;
}

/************/

.cursor-pointer {
  cursor: pointer;
}

.h-line {
  line-height: 2.5 !important;
}

/**************BAGROUND COLORs******************/

.background-image-bubble {
  background-image: url('../../assets/image/fondo.png');
  background-attachment: fixed;
  background-size: contain;
}

.background-image-glingenieros {
  background-image: url('../../assets/image/glIngenieros.png');
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  justify-items: center;
}

.bg-white {
  background: white;
}

.background-view {
  background: $third !important;
}

.bg-third {
  background: $third;
}

.bg-green {
  background: $green !important;
}

.bg-green-gradient {
  background: $green-gradient !important;
}

.bg-third {
  background: $third !important;
}

/******** DISPLAY ***********/

.d-flex {
  display: flex;
}

.center {
  justify-content: center;
}

.bg-primary {
  background: $primary !important;
}

.bg-secundary {
  background: $secundary !important;
}

.bg-third {
  background: $third !important;
}

.bg-white {
  background: white !important;
}

.bg-co2-primary {
  background: $co2Primary !important;
}
.bg-co2-secundary {
  background: $co2Secundary !important;
}

.bg-co2-grey {
  background: #d6d4d4 !important;
}

//* charts */

//++************************CUSTOM CLASSES********************************+

.principal-title {
  font-weight: bold;
  text-decoration: underline;
  font-size: x-large;
}

.secundary-title {
  font-weight: bold;
  font-size: x-large;
}

.ff-poppins {
  font-family: 'Poppins', sans-serif !important;
}

.ff-poppins-small {
  font-family: Verdana;
  font-size: xx-small;
}

.input-element fieldset {
  border: 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

//***************************** table ****/

.table-master {
  @extend .border-10;
  max-height: 350px;
}
.table-master-495 {
  @extend .border-10;
  max-height: 495px;
}

.table-master-500px {
  @extend .border-10;
  max-height: 500px;
}

.table-master-443 {
  @extend .border-10;
  max-height: 443px;
}
//*********************++borders***/

.border-10 {
  border-radius: 10px !important;
}

.border-global {
  @extend .border-10;
}

.border-30 {
  border-radius: 30px !important;
}

//***********************+size**/

.w-50px-important {
  width: 50px !important;
}
.w-120px {
  width: 120px;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100%;
}

.h-80 {
  height: 80%;
}

.h-50 {
  height: 50% !important;
}

.h-98 {
  height: 98% !important;
}
.h-60px {
  height: 60px !important;
}

.oy-hidden {
  overflow-y: hidden;
}

.oy-auto {
  overflow-y: auto;
}

.ox-hidden {
  overflow-x: hidden;
}

.o-hidden {
  overflow: hidden;
}

.o-scroll {
  overflow: scroll !important;
}
.oy-scroll {
  overflow-y: scroll;
}

.h-410px {
  height: 410px;
}

.h-420px {
  height: 455px;
}

.h-450px {
  height: 450px;
}

.h-510px {
  height: 510px !important;
}

.h-403px {
  height: 403px;
}

.h-190px {
  height: 190px;
}

.h-320px {
  height: 320px;
}

.h-50px {
  height: 50px;
}

.h-115 {
  height: 115% !important;
}
.h-110 {
  height: 110% !important;
}
.h-105 {
  height: 103% !important;
}

//*************************POSITIONS

.absolute {
  position: absolute;
}

.abso {
  position: relative;
  top: -60px;
  left: 250px;
}

//***********************+buttons**/

.btn-rounded-primary {
  @extend .bg-primary, .fc-third, .border-30, .w-100, .fw-bold;
}

.btn-rounded-secundary {
  @extend .bg-secundary, .fc-third, .border-30, .w-100, .fw-bold;
}

.btn-rounded-third {
  @extend .bg-third, .fc-secundary, .border-30, .w-100, .fw-bold, .pa-3;
}

.btn-rounded-green {
  @extend .bg-green, .fc-secundary, .border-30, .w-100, .fw-bold, .pa-3;
}

.bg-green-gradient {
  @extend .bg-green-gradient, .fc-third, .border-30, .fw-bold, .pa-3;
}
.btn-transparent {
  background: transparent;
}

.center-info {
  justify-content: center;
  display: flex;
}

.center-icon-char {
  align-items: center;
  display: flex;
}

.center-right {
  justify-content: flex-end;
  display: flex;
}
.center-r {
  justify-content: space-between;
}

.center-info-important {
  justify-content: center !important;
  display: flex !important;
}
//***************************CUSTOM CLASS

.paper {
  @extend .h-100, .border-global;
}

.fc-green-input-label .MuiFormLabel-root {
  @extend .fc-green;
}

.jc-center {
  justify-content: center;
}

.center-items {
  @extend .jc-center, .d-flex;
}

.global-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-paper {
  @extend .absolute, .bg-third;
  width: 400px;
  border: 2px solid #000;
  box-shadow: 5px;
  padding: 2, 4, 3;
}

//***************************ICON

.icon-rotate-90 {
  transform: rotate(90deg);
}

.min-width-1px {
  min-width: 1px !important;
}
//**************************C3 CHART
.c3-axis-y text {
  font-size: 13px;
}

.c3-axis-x text {
  font-size: 11px;
}
//**************************Alert
.swal-button--confirm {
  background: $green;
}
//**************************Media query

@media only screen and (min-width: 801px) {
  .fs-home-info {
    font-size: 1.8rem !important;
  }
  .fs-alarm {
    font-size: 1.8rem !important;
  }
  .size-modal {
    width: 50% !important;
  }
}

@media only screen and (max-width: 420px) {
  .icon-co2 {
    display: none;
  }
  .size-modal {
    width: 100% !important;
    height: 100% !important;
  }
  .fs-home-info {
    font-size: 1.5rem !important;
  }
  .fs-alarm {
    font-size: 1.2rem !important;
  }
}
.grid-border {
  border-top: 4px solid #3dcd58;
  border-right: 4px solid #3dcd58;
  border-bottom: 4px solid #3dcd58;
  border-left: 4px solid #3dcd58;
  border-radius: 30px;
}

.grid-border-blue {
  border-top: 4px solid #1d2877;
  border-right: 4px solid #1d2877;
  border-bottom: 4px solid #1d2877;
  border-left: 4px solid #1d2877;
  border-radius: 20px;
}

.c3-axis-y2 {
  font-size: 13px;
  @extend .fw-bold;
}
.c3-axis-y {
  font-size: 13px;
  @extend .fw-bold;
}
.c3-axis-x {
  font-size: 13px;
  @extend .fw-bold;
}

.c3-ygrid-line line {
  stroke: red;
}
.c3-ygrid-line.grid800 line {
  stroke: red;
}
.c3-ygrid-line.grid800 text {
  fill: red;
  @extend .fw-bold;
}
