@use "../../../assets/styles/App.scss";
@import '../../../assets/styles/App.scss';

.background {
  background-image: url('../../../assets/image/image-login.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.input-login {
  text-align: center;
  border-color: white;
  height: 100%;
  border: solid white !important;
  width: 100%;
  border-radius: 100vh;
}

.input-login .MuiInputLabel-root {
  color: white;
  width: 100%;
}

.input-login .MuiInputLabel-root {
  color: white;
}

.input-login .MuiInputLabel-root .Mui-focused {
  color: white;
}

.input-login .MuiFormLabel-root.Mui-focused {
  color: white !important;
}

.input-login .MuiInput-underline:before {
  content: none;
}

.input-login .MuiInput-underline:after {
  content: none;
}

.input-login label + .MuiInput-formControl {
  margin-bottom: 16px;
}

.input-login .MuiInputBase-input {
  font-weight: bold !important;
  text-align: center;
  color: white;
}

.button-login .MuiButtonBase-root {
  background: linear-gradient(180deg, rgba(92, 229, 118, 1) 0%, rgba(42, 78, 48, 1) 100%);
  color: white;
  border-radius: 100vh;
  width: 100%;
  padding: 8px;
}

.center-info {
  justify-content: center;
  display: flex;
}

.container .MuiGrid-spacing-xs-2 {
  margin: 0px !important;
  width: 100% !important;
}

.log-out-text .MuiTypography-root {
  @extend .fw-bold, .fc-third, .ff-poppins;
}

.check-box .MuiSvgIcon-root {
  @extend .fc-green;
}

.icon-login .MuiIconButton-root {
  color: #fff !important;
}

@media (max-width: 360px) {
  .background {
    background: $primary;
  }
}

@media only screen and (max-width: 420px) {
  .position_input {
    margin-left: 150px !important;
    width: 352px !important;
    margin-top: 88px !important;
  }
  .h-680px {
    height: 680px !important;
  }
  .img-letter {
    height: 21vh;
  }
  .img-letter2 {
    height: 21vh;
    display: none;
  }
  .m-left-129px {
    margin-left: 129px !important;
  }
  .fc-black {
    color: black !important;
  }
  .m-left-48px {
    margin-left: 48px !important;
  }
  .input-login {
    text-align: center;
    border-color: black;
    height: 100%;
    border: solid black !important;
    width: 100%;
    border-radius: 100vh;
  }
  .input-login .MuiInputLabel-root {
    color: black;
    width: 100%;
  }

  .input-login .MuiInputLabel-root {
    color: black;
  }

  .input-login .MuiInputLabel-root .Mui-focused {
    color: black;
  }

  .input-login .MuiFormLabel-root.Mui-focused {
    color: black !important;
  }
  .icon-login {
    color: #474b4e;
  }

  .modal-recover-pass {
    position: absolute !important;
    width: 100% !important;
    margin-top: 245px !important;
    background: black !important;
    height: 201px !important;
    border: 2px solid #000;
  }
  .icon-login .MuiIconButton-root {
    color: black !important;
  }
  .input-login .MuiInputBase-input {
    font-weight: bold !important;
    text-align: center;
    color: black;
  }
}

@media only screen and (min-width: 421px) and (max-width: 800px) {
  .position_input {
    margin-left: 150px !important;
    width: 352px !important;
    margin-top: -14px !important;
  }
  .h-680px {
    height: 723px !important;
  }
  .img-letter {
    height: 21vh;
  }
  .img-letter2 {
    height: 21vh;
  }
  .m-left-129px {
    margin-left: 129px !important;
  }
  .fc-black {
    color: black !important;
  }
  .m-left-48px {
    margin-left: 48px !important;
  }
  .fc-black {
    color: white !important;
  }
  .icon-pass {
    color: white !important;
  }
  .icon-login {
    color: #474b4e;
  }
  .modal-recover-pass {
    position: absolute !important;
    width: 100% !important;
    margin-top: 245px !important;
    background: white !important;
    height: 201px !important;
    border: 2px solid #000;
  }
  .input-login .MuiInputBase-input {
    font-weight: bold !important;
    text-align: center;
    color: black;
  }
}

@media only screen and (min-width: 801px) {
  .fc-black {
    color: white !important;
  }
  .icon-pass {
    color: white !important;
  }
  .icon-login {
    color: #474b4e;
  }
  .modal-recover-pass {
    position: absolute !important;
    width: 50% !important;
    margin-top: 245px !important;
    background: white !important;
    height: 201px !important;
    margin-left: 26%;
    border: 2px solid #000;
  }

  .img-letter {
    height: 15vh;
  }
  .img-letter2 {
    height: 21vh;
    display: none;
  }
}
