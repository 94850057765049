.c3-region.limit {
  fill: red;
}

.c3-region.regionY {
  fill: red;
}
.c3-region.regionY2 {
  fill: green;
}
