@import '../../assets/styles/App.scss';

.styled-device {
  background-color: $green;
  opacity: 1;
}

.no-styled-device {
  opacity: 0.7;
}
